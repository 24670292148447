import { render, staticRenderFns } from "./ProvidersTable.vue?vue&type=template&id=066f9352&scoped=true"
import script from "./ProvidersTable.vue?vue&type=script&lang=js"
export * from "./ProvidersTable.vue?vue&type=script&lang=js"
import style0 from "./ProvidersTable.vue?vue&type=style&index=0&id=066f9352&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "066f9352",
  null
  
)

export default component.exports