<template>
  <div>
    <b-table-simple class="p-table" striped>
      <tbody>
        <tr>
          <td class="td-header">Nome / Razão social</td>
          <td class="td-header">CNPJ</td>
          <td class="td-header">E-mail</td>
          <td class="td-header">Telefone</td>
          <td class="td-header">Cidade/Estado</td>
          <td class="td-header"></td>
        </tr>
        <tr v-for="provider in providers" :key="provider.id">
          <td class="td-body">
            <div class="name">{{ provider.name }}</div>
          </td>
          <td class="td-body td-simple">{{ provider.cnpj || '-' }}</td>
          <td class="td-body td-simple">{{ provider.email || '-'}}</td>
          <td class="td-body td-simple">{{ provider.phone || '-' }}</td>
          <td class="td-body td-simple">{{ provider.address.city }}, {{ provider.address.state }}</td>
          <td class="td-body">
            <div v-if="userHasPermission('EstFor3') || userHasPermission('EstFor4')" class="more-with-menu">
              <MoreVertical class="more-icon" />
              <div class="menu">
                <b-button
                  v-can="'EstFor3'"
                  variant="link"
                  @click="onEdit(provider)"
                >
                  Editar
                </b-button
                >
                <b-button
                  v-can="'EstFor4'"
                  variant="link remove mb-0"
                  @click="onDeleteClick(provider)"
                >
                  Excluir
                </b-button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </b-table-simple>

    <EmptyTableResults
      emptyMessage="Cadastre um fornecedor para visualizá-lo aqui."
      :loading="loading"
      :length="providers ? providers.length : 0"
      :hasFilter="hasFilter"
    />
  </div>
</template>
<script>
import { userHasPermission } from '@/utils/localStorageManager'

export default {
  props: {
    providers: { type: Array, required: true },
    onEdit: Function,
    onDelete: Function,
    loading: Boolean,
    hasFilter:  { validate: v => true },
  },
  components: {
    MoreVertical: () => import('@/assets/icons/more-vertical.svg'),
    EmptyTableResults: () => import('@/components/General/EmptyTableResults'),
  },
  methods: {
    userHasPermission,
    onDeleteClick(provider) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Você tem certeza que deseja excluir <span> ${provider.name}</span>? Não será possível reverter essa ação.</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
       }).then(res => {
        if (res.isConfirmed) {
          this.onDelete(provider)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .p-table {
    width: 100%;
    text-align: left;
    margin: 20px 0;

    .td-header {
      font-weight: 700;
      font-size: 14px;
      color: var(--dark-blue);
      border: none;
      padding: 20px 10px;
    }
    .td-body {
      padding: 20px 10px;
      border-top: 1px dashed var(--neutral-300);
      font-size: 16px;
      color: var(--dark-blue);
    }
    .td-simple {
      font-weight: normal !important;
    }
    .name {
      font-weight: 600;
    }
    .more {
      svg {
        stroke: var(--neutral-500);
      }
    }
    .edit-text {
      font-size: 16px;
      font-weight: 600;
      color: var(--blue-500);
    }
    .delete-text {
      font-size: 16px;
      font-weight: 600;
      color: var(--states-error);
    }
  }
  .more-with-menu {
    width: 24px;
    height: 28px;
    position: relative;
    display: inline-block;

    .more-icon {
      width: 24px;
      height: 24px;
      stroke: var(--neutral-500);
      transition: all 0.5s;
      cursor: pointer;
    }

    .menu {
      position: absolute;
      top: 28px;
      right: 0;
      background: var(--neutral-000);
      border: 1px solid var(--neutral-200);
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1), 0px 4px 10px -8px rgba(12, 29, 89, 0.2);
      border-radius: 8px;
      opacity: 0;
      transition: all 0.5s;
      flex-direction: column;
      align-items: start;
      display: none;

      .mtb-12 {
        margin: 12px 0;
      }

      .btn {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;

        &.remove {
          color: var(--states-error);
        }

        &.btn-link {
          padding: 12px 80px 12px 16px !important;
        }
      }
    }

    &:hover {
      .more-icon {
        transform: rotate(90deg);
      }

      .menu {
        opacity: 1;
        display: flex;
        z-index: 10;
      }
    }
  }
</style>
