<template>
  <div class="pt-container">
    <div class="pt-header">
      <div class="pt-title">
        Fornecedores
        <span id="help-icon" class="icon-box"><v-help-circle class="icon stroke" /></span>
        <b-tooltip target="help-icon" placement="bottom">
          Tenha controle dos fornecedores dos produtos em estoque, guarde informações como endereço, contato, e-mail e CNPJ.
        </b-tooltip>
      </div>

      <div>
        <b-button class="wh-button no-print" variant="outline-primary" outline @click="getXlsx">
            Download xlsx
        </b-button>

        <b-button v-can="'EstFor2'" class="whw-button no-print" variant="primary" outline @click="addProvider">
            Adicionar fornecedor
        </b-button>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <b-form-group class="form-group">
          <label for="search">Fornecedor</label>
          <b-form-input
            id="search"
            autocomplete="off"
            debounce="500"
            placeholder="Buscar fornecedor"
            v-model="query"
          />
        </b-form-group>
      </div>

      <div class="col-3">
        <b-form-group class="form-group">
          <label for="search">Estado</label>
          <v-autocomplete
            v-model="state"
            :options="states"
          />
        </b-form-group>
      </div>

      <div class="col-3">
        <b-form-group class="form-group">
          <label for="search">Cidade
            <span v-if="!state" id="help-icon-city" class="icon-box"><v-help-circle class="small-icon stroke" /></span>
            <b-tooltip target="help-icon-city" placement="bottom">
              Selecione um estado para buscar uma cidade.
            </b-tooltip>
          </label>

          <v-autocomplete
            :options="cities[state] ? cities[state].cities.map(c => ({ label: c, value: c })) : []"
            v-model="city"
          />
        </b-form-group>
      </div>
    </div>

    <v-provider-modal
      :provider="provider"
      v-on:onHide="getProviders"
    />

    <v-providers-table
      :providers="providers"
      :onEdit="onEditClick"
      :onDelete="deleteProvider"
      :loading="loading"
      :hasFilter="hasFilter"
    />

    <div class="pagination-position">
      <b-pagination
        size="sm"
        v-model="page"
        :total-rows="count"
        :per-page="limit"
        first-number
        last-number
      />
    </div>

  </div>
</template>
<script>
import { saveAs } from 'file-saver';
import HelpCircle from '@/assets/icons/help-circle.svg'
import states from '@/utils/states'
import cities from '@/utils/cities'
import Autocomplete from '@/components/Autocomplete'
import ProviderModal from '@/components/Warehouse/ProviderModal'
import ProvidersTable from '@/components/Warehouse/ProvidersTable'
export default {
  name: 'products-table',
  components: {
    'v-help-circle': HelpCircle,
    'v-autocomplete': Autocomplete,
    'v-provider-modal': ProviderModal,
    'v-providers-table': ProvidersTable
  },
  props: {},
  mounted () {
    this.getProviders();
  },
  data() {
    const clinic = JSON.parse(localStorage.getItem('clinic'));
    return {
      provider: null,
      query: '',
      state: null,
      city: null,
      page: 1,
      limit: 0,
      count: 0,
      clinicId: clinic.id,
      providers: [],
      states: states.map(s => ({ label: s.initial, value: s.initial })),
      cities,
      loading: false,
      hasFilter: false,
    }
  },
  methods: {
    getProviders() {
      this.loading = true;
      this.hasFilter = (this.query || this.state || this.city);
      const isLoading = this.$loading.show();
      this.api.getProviders(this.clinicId, this.page, this.query, this.state, this.city)
      .then(res => {
        this.providers = res.data.data;
        this.count = res.data.total;
        this.limit = res.data.per_page;
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => {
        this.loading = false;
        isLoading.hide();
      });
    },
    deleteProvider(provider) {
      const isLoading = this.$loading.show();
      this.api.deleteProvider(provider.id)
      .then(() => {
        this.getProviders();
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => isLoading.hide());
    },
    addProvider() {
      this.provider = null;
      this.$bvModal.show('provider-modal');
    },
    onEditClick(provider) {
      this.provider = Object.assign({}, provider);
      this.$bvModal.show('provider-modal');
    },
    getXlsx() {
      const isLoading = this.$loading.show();
      const data = {
        'query': this.query,
        'state': this.state,
        'city': this.city
      }
      this.api.getWarehouseProvidersExport(this.clinicId, data)
      .then(res => {
        saveAs(
          new Blob([res.data], {
            type: 'application/vnd.ms-excel',
          }),
          'fornecedores.xlsx'
        );
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => isLoading.hide());
    },
  },
  watch: {
    page: function() { this.getProviders(); },
    query: function() { this.getProviders(); },
    state: function() { this.getProviders(); },
    city: function() { this.getProviders(); },
  }
}
</script>
<style scoped lang="scss">
  .pt-container {
    padding: 30px 10px;
    .pt-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0 30px 0;
    }
    .pt-title {
      display: inline-flex;
      align-items: center;
      font-family: 'Red Hat Display';
      font-weight: 500;
      font-size: 18px;
    }
    .icon {
      height: 24px;
      width: 24px;
      margin-left: 5px;
      stroke: var(--neutral-500);
    }
    .small-icon {
      width: 20px;
      margin-left: 5px;
    }
    .pagination-position {
      width: 100%;
      display: inline-flex;
      justify-content: flex-end;
    }
    label {
      width: 100%;
      font-family: 'Nunito Sans';
      font-weight: 700;
      font-size: 16px;
      color: var(--type-active);
      text-align: left !important;
      color: var(--dark-blue);
      margin-bottom: 4px;
    }
  }
  .wh-button {
    margin: 0 0 0 10px;
    border-color: var(--blue-500);
    color: var(--blue-500);
  }

  .whw-button {
    margin: 0 0 0 10px;
    border-color: var(--blue-500);
    color: var(--default-white);
  }
</style>
